<template>

  <div class="page-container">

    <router-link to="/">
      <img class="logo" src="@/assets/images/esprezzo-logo.png" />
    </router-link>

    <div ref="containerElement" class="container">

      <LoadingOverlay v-if="form.isLoading" />

      <template v-if="currentStep === 1">
        <h4>Forgot your password?</h4>
        <p class="mb-4">
          Don't worry! Enter the email address for your Dispatch account and we'll
          send you a link to reset it.
        </p>

        <form @submit="submit">
          <FormSubmitErrors :formName="formName" />
          <FormInput :formName="formName" fieldName="email" ref="focusElement" />
          <button type="submit" :disabled="isAPIReadOnly || form.isLoading || !isFormValid">Send reset link</button>
          <p class="after-button-note">
            Nevermind! <router-link to="/login">Take me back to login.</router-link>
          </p>
        </form>
      </template>

      <template v-if="currentStep === 2">
        <h4>Almost there!</h4>
        <p class="text-left">
          If your email is associated with an Esprezzo Dispatch account, you
          will receive instructions for resetting your password.
          <br>
          <br>
          If you haven't received an email from Esprezzo in 15 minutes, please
          check your spam folder.
          <br>
          <br>
          Still haven't recevied your password reset email?
          <br>
          <a target="_blank" href="mailto:support@esprezzo.io?subject=Missing%20Password%20Reset%20Email&body=I%20have%20not%20yet%20received%20my%20requested%20password%20reset%20email.">Let us know.</a>
        </p>
      </template>

    </div>

    <p class="recaptcha-note">
      Invisible reCAPTCHA by Google
      <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a>.
    </p>

  </div>

</template>

<script>

  import { ref } from 'vue'
  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'
  import useRecaptcha from '@/composables/useRecaptcha'

  import FormInput from '@/components/forms/FormInput.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  export default {
    components: {
      FormInput,
      LoadingOverlay,
      FormSubmitErrors,
    },
    setup() {

      // data
      const currentStep = ref(1)
      const formName = 'forgotPasswordForm'

      // composables
      const { setRecaptchaToken } = useRecaptcha({ formName, action: 'forgot_password' })
      const { form, isFormValid, focusElement, scrollToFormTop, containerElement } = useForm({ formName })

      return {
        form,
        formName,
        isFormValid,
        currentStep,
        focusElement,
        scrollToFormTop,
        containerElement,
        setRecaptchaToken,
      }

    },
    computed: {
      ...mapState('app', ['isAPIReadOnly']),
    },
    created() {
      this.$store.commit('forms/SET_FIELD_DISABLED', { formName: this.formName, fieldName: 'email', newValue: this.isAPIReadOnly })
    },
    methods: {
      submit($event) {

        if ($event) $event.preventDefault()

        this.setRecaptchaToken()
          .then(() => {

            return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
              .then((result) => {
                this.currentStep = 2
              })
              .catch(() => {
                // do nothing
              })

          })
          .finally(() => {
            this.scrollToFormTop()
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/logged-out-page.styl'

</style>
